import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink/index"
import { graphql, StaticQuery } from "gatsby"

const Moment = require('moment');
const pluralize = require('pluralize')

function scrollToY(scrollTargetY, speed, easing) {
  // scrollTargetY: the target scrollY property of the window
  // speed: time in pixels per second
  // easing: easing equation to use

  var scrollY = window.scrollY,
    scrollTargetY = scrollTargetY || 0,
    speed = speed || 2000,
    easing = easing || 'easeOutSine',
    currentTime = 0;

  // min time .1, max time .8 seconds
  var time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));

  // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
  var PI_D2 = Math.PI / 2,
    easingEquations = {
      easeOutSine: function (pos) {
        return Math.sin(pos * (Math.PI / 2));
      },
      easeInOutSine: function (pos) {
        return (-0.5 * (Math.cos(Math.PI * pos) - 1));
      },
      easeInOutQuint: function (pos) {
        if ((pos /= 0.5) < 1) {
          return 0.5 * Math.pow(pos, 5);
        }
        return 0.5 * (Math.pow((pos - 2), 5) + 2);
      }
    };

  // add animation loop
  function tick() {
    currentTime += 1 / 60;

    var p = currentTime / time;
    var t = easingEquations[easing](p);

    if (p < 1) {
      requestAnimationFrame(tick);

      window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
    } else {
      window.scrollTo(0, scrollTargetY);
    }
  }

  // call it once to get started
  tick();
}

function scrollToTop() {
  if (typeof window !== "undefined") {
    if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
      scrollToY(0, 1500, 'easeInOutQuint');
    }
  }
}

class Footer extends React.Component {
  render() {
    let social = [];

    return <StaticQuery
      props={this.props}
      query={graphql`
          query FooterQuery {
            allNavigation {
              nodes {
                options {
                  showSheyiSection
                  social {
                    url, 
                    icon,
                    white_icon
                  }
                }
              }
            }
          }
        `}
      render={(data) => {

        var social = data.allNavigation.nodes[0].options.social;

        // if (typeof this.props.pageContext.navigation !== 'undefined') {
        //   social = this.props.pageContext.navigation[0].options ? this.props.pageContext.navigation[0].options.social : this.props.pageContext.navigation[1].options.social;
        // }
        let linkPrefix = '/article/';
        if (this.props.articleType && this.props.articleType === 'project') {
          linkPrefix = '/project/';
        } else if (this.props.articleType === 'sheyi-project') {
          linkPrefix = '/sheyi-bankale-project/';
        }

        if (this.props.articleType && this.props.articleType === 'sheyi') {
          linkPrefix = '/sheyi/';
        }

        return (
          <footer className="main">
            <div className="container-fluid">
              <div className="row d-flex align-items-center">
                <div className="col">
                  {this.props.article && this.props.article.nextArticle && this.props.article.nextArticle.title &&
                  <p className="next"><span
                    style={{ textTransform: 'capitalize' }}>Next {this.props.projectType ? pluralize.singular(this.props.projectType) : 'article'}</span> : {this.props.article.nextArticle.title}
                  </p>
                  }
                </div>

                <div className="col-2 col-lg-1">
                  {this.props.article && this.props.article.previousArticle && this.props.article.previousArticle.title &&
                  <AniLink className={"arrow"} cover direction={"right"} bg={'#b0383a'}
                           to={linkPrefix + this.props.article.previousArticle.slug}>
                    {this.props.isSheyi &&
                    <img className="rotate-180" src={require('../images/arrow-white.svg')} alt=""/>}
                    {!this.props.isSheyi && <img className="rotate-180" src={require('../images/arrow.svg')} alt=""/>}
                  </AniLink>
                  }
                </div>

                <div className="col-2 col-lg-1 text-center">
                  {this.props.article && this.props.article.nextArticle && this.props.article.nextArticle.title &&
                  <AniLink className={"arrow"} cover direction={"left"} bg={'#b0383a'}
                           to={linkPrefix + this.props.article.nextArticle.slug}>
                    {this.props.isSheyi && <img src={require('../images/arrow-white.svg')} alt=""/>}
                    {!this.props.isSheyi && <img src={require('../images/arrow.svg')} alt=""/>}
                  </AniLink>
                  }
                </div>
              </div>

              <div className="bottom">
                <div className="row d-flex align-items-end">
                  <div className="col-lg-5 d-none d-lg-block">
                    <p>Next Level Projects UK ©{new Moment().format('Y')}</p>
                  </div>

                  <div className="col-lg-6 offset-lg-1">
                    <div className="row align-items-end justify-content-end">
                      {social && social.map((item, key) => {
                        return (
                          <div key={key} className={key === 0 ? "col text-md-center" : "col text-md-center"}>
                            {this.props.isSheyi &&
                            <a target={'_blank'} href={item.url} className="social"><img src={item.white_icon} alt=""/></a>}
                            {!this.props.isSheyi &&
                            <a target={'_blank'} href={item.url} className="social"><img src={item.icon} alt=""/></a>}
                          </div>
                        )
                      })}

                      <div className="col col-lg-2 text-center">
                        <button onClick={scrollToTop} className="arrow">
                          {this.props.isSheyi &&
                          <img className="rotate-90" src={require('../images/arrow-white.svg')} alt=""/>}
                          {!this.props.isSheyi &&
                          <img className="rotate-90" src={require('../images/arrow.svg')} alt=""/>}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-lg-none">
                  <div className="col-12">
                    <p className={"copy-mobile"}>Next Level Projects UK ©{new Moment().format('Y')}</p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        );
      }}
    />
  }
}

export default Footer;
